<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="true"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img src="favicon.png" max-height="50" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h4" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!-- <template v-slot:append>
      <base-item
        :item="{
          title: $t('upgrade'),
          icon: 'mdi-package-up',
          to: '/upgrade',
        }"
      />
    </template>-->
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/',
      },
      {
        icon: 'mdi-list-box',
        title: 'Pfizer',
        to: '/table/pfizer',
      },
      {
        icon: 'mdi-list-box',
        title: 'Moderna',
        to: '/table/moderna',
      },
      {
         icon: 'mdi-heart-off',
         title: '死亡報告',
         to: '/death',
      },
      {
         icon: 'mdi-heart-flash',
         title: '心筋炎/心膜炎報告',
         to: '/myocarditis',
      },
      {
         icon: 'mdi-file-check',
         title: '健康被害認定一覧',
         to: '/approved',
      },
      {
         icon: 'mdi-alert-circle-check',
         title: '認定副反応一覧',
         to: '/approved_disease',
      },

      // {
      //   title: 'rtables',
      //   icon: 'mdi-clipboard-outline',
      //   to: '/tables/regular-tables',
      // },
      // {
      //   title: 'typography',
      //   icon: 'mdi-format-font',
      //   to: '/components/typography',
      // },
      // {
      //   title: 'icons',
      //   icon: 'mdi-chart-bubble',
      //   to: '/components/icons',
      // },
      // {
      //   title: 'google',
      //   icon: 'mdi-map-marker',
      //   to: '/maps/google-maps',
      // },
      // {
      //   title: 'notifications',
      //   icon: 'mdi-bell',
      //   to: '/components/notifications',
      // },
    ],
  }),

  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    computedItems() {
      return this.items.map(this.mapItem)
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },
  },
}
</script>
